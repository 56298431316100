import React, { useState } from "react";
import { ThumbnailGrid } from "./ThumbnailGrid.jsx";
import { Pagination } from "../Pagination.jsx";
import {
  SelectWrap,
  SelectTitle,
  SelectBox,
  SelectItem,
  SelectRadio,
} from "../../../../../components/atoms/SelectBox.js";

const IMG_STATUS = {
  all_cnt: {
    code: "all_cnt",
    title: "전체",
    color: "#333",
  },
  val_cnt: {
    code: "val_cnt",
    title: "선별 데이터",
    color: "#333",
  },
};

const IMG_STATUS_VAL = {
  auto_cnt: {
    code: "auto_cnt",
    title: "자동선별 데이터",
    color: "#333",
  },
  excluded_cnt: {
    code: "excluded_cnt",
    title: "- 탈락 데이터",
    color: "#333",
  },
  added_cnt: {
    code: "added_cnt",
    title: "+ 추가 데이터",
    color: "#333",
  },
};

const IMG_FILTER = {
  all: {
    code: 1,
    title: "전체",
  },
  valid: {
    code: 2,
    title: "선별",
  },
  auto: {
    code: 3,
    title: "자동선별",
  },
  added: {
    code: 4,
    title: "추가",
  },
  excluded: {
    code: 3,
    title: "탈락",
  },
};

export const ThumbnailView = ({
  category,
  selectedCell,
  resetSelectedDataset,
  selectedDataset,
  handleSetHoveredThumbnail,
}) => {
  const [page, setPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedImgStatus, setSelectedImgStatus] = useState(IMG_FILTER.all);
  const [countsByImgStatus, setCountsByImgStatus] = useState({
    all_cnt: 0,
    auto_cnt: 0,
    lrn_cnt: 0,
    val_cnt: 0,
    added_cnt: 0,
    excluded_cnt: 0,
  });

  return (
    <div className="flex h-full flex-[1_0_0%] flex-col gap-[16px] overflow-auto">
      <div className="flex h-full flex-grow flex-col gap-[16px] border border-[#e1e1e1] p-[16px]">
        {selectedCell ? (
          <>
            <div className="flex justify-between">
              <div className="flex items-center gap-[8px]">
                <PrevButton handleClick={resetSelectedDataset} />
                <div className="text-[18px] font-bold leading-[23px] tracking-[-0.36px] text-[#333]">{`${selectedDataset.name}`}</div>
              </div>
              <div className="flex gap-[8px]">
                <SelectWrap single>
                  <SelectTitle
                    name={selectedImgStatus.title}
                    onClick={() => {
                      setOpenFilter((openFilter) => !openFilter);
                    }}
                  />
                  {openFilter && (
                    <SelectBox open={openFilter}>
                      {Object.values(IMG_FILTER).map((filter, index) => (
                        <SelectItem
                          key={`${filter.code}-${filter.title}`}
                          index={index}
                          item={{ ko: filter.title }}
                          styleType="list"
                          cn="truncate"
                        >
                          <SelectRadio
                            onChange={() => {
                              setPage(1);
                              setOpenFilter(false);
                              setSelectedImgStatus(filter);
                            }}
                            checked={selectedImgStatus === filter}
                            value={filter}
                          />
                        </SelectItem>
                      ))}
                    </SelectBox>
                  )}
                </SelectWrap>
              </div>
            </div>
            <div className="ml-auto flex">
              <div className="flex items-center gap-[8px]">
                {Object.values(IMG_STATUS).map(({ title, code, color }) => (
                  <Count key={code} title={title} color={color} count={countsByImgStatus[code]} />
                ))}
              </div>
              <div className="ml-[4px] flex">
                (
                <div className="flex items-center gap-[8px]">
                  {Object.values(IMG_STATUS_VAL).map(({ title, code, color }) => (
                    <Count key={code} title={title} color={color} count={countsByImgStatus[code]} />
                  ))}
                </div>
                )
              </div>
            </div>
            <ThumbnailGrid
              category={category}
              selectedImgStatus={selectedImgStatus}
              selectedDataset={selectedDataset}
              selectedCell={selectedCell}
              page={page}
              setTotalPage={setTotalPage}
              setCountsByImgStatus={setCountsByImgStatus}
            />
          </>
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex items-center gap-[8px]">
                <PrevButton handleClick={resetSelectedDataset} />
                <div className="text-[18px] font-bold leading-[23px] tracking-[-0.36px] text-[#333]">{`${selectedDataset.name}`}</div>
              </div>
              <div className="flex items-center gap-[2px] text-[14px] leading-[18px] tracking-[-0.28px] text-[#E20000]">
                <div className={`h-[24px] w-[24px] bg-[url("../icons/icon_alert.png")]`} />셀 선택
                시 데이터를 확인할 수 있습니다.
              </div>
            </div>
            <div className="flex h-full items-center justify-center bg-[#f2f2f2]">
              <div className="text-center text-[16px] font-normal leading-[20px] tracking-[-0.32px] text-[#BEBEBE]">
                <div>선택한 셀이 없습니다.</div>
                <div>샘플 데이터는 셀 단위로 제공됩니다.</div>
              </div>
            </div>
          </>
        )}
        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
      </div>
    </div>
  );
};

const PrevButton = ({ handleClick }) => (
  <button className="flex h-[24px] w-[24px] items-center justify-center" onClick={handleClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
      <path
        d="M7.5 1L1.5 7L7.5 13"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

const Count = ({ title, color, count }) => (
  <div className="flex items-center gap-[4px]">
    <div className="text-[12px] font-normal leading-[15px] tracking-[-0.24px] text-[#333]">
      {title}:
    </div>
    <div className={`text-[14px] font-bold leading-[18px] tracking-[-0.28px]`} style={{ color }}>
      {count}
    </div>
  </div>
);

import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import clsx from "clsx";
import { CONDITIONS } from "../../lib/constants/constant";
import IC_empty from "../../assets/images/IC_empty.png";
import { ROWS_PER_PAGE } from "../../lib/constants/constant";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { EmptyImg } from "../atoms/Status";
import { DrawCondTag, CondTag, LabelTag, DrawVldTag } from "../organisms/CommonTags";
import { StepIndicator } from "../atoms/CommonAtoms";
import GetPreview from "../organisms/GetPreview";

export const ThumbnailItem = React.memo(
  ({
    index,
    item,
    currentPage,
    previewQuery,
    tag,
    selected,
    handleSelectThumbItem,
    currentImgType,
  }) => {
    let isShiftDown = false;
    useHotkeys("shift", () => (isShiftDown = true), { keydown: true });
    useHotkeys("shift", () => (isShiftDown = false), { keyup: true });

    return (
      <li
        key={item.id}
        className="thumb_item relative m-[20px] flex w-[400px] flex-col gap-[16px]"
        // 우클릭 방지
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="flex min-w-0 flex-shrink-0 flex-grow items-center overflow-hidden">
          <div className="truncate">
            <SelectItem index={index} item={item}>
              <SelectCheckbox
                onChange={() => handleSelectThumbItem(item.id, isShiftDown)}
                checked={selected}
                value={item.id}
              />
              <div className="truncate pl-[8px] font-bold text-[#333]">
                {index + 1 + (currentPage - 1) * ROWS_PER_PAGE}
                {". "}
                {item.filename}
              </div>
            </SelectItem>
          </div>
          {(tag === "eval" || tag === "val") && (
            <div className="ml-auto flex-shrink-0">
              <DrawVldTag item={item.vld_type} tag={tag} />
              {/* 미리보기 기준 UI */}
              {/* <DrawVldTagForPreview item={item} tag={tag} id={item.id} /> */}
            </div>
          )}
        </div>
        <div className="flex items-center gap-[15px]">
          {/* 데이터 검수 라벨 태그 - 난이도, 연기, 작업자 */}
          {tag === "dist" && (
            <>
              {item.rework_cnt > 0 && <StepIndicator step={item.rework_cnt} />}
              {(item.difficulty || item.worker) && (
                <LabelTag
                  tags={[{ name: item.difficulty }, { name: item.worker?.display_name ?? "-" }]}
                />
              )}
              {/* 수집환경이 있을 경우 */}
              {item.conds && <CondTag conds={item.conds} />}
            </>
          )}
          {/* 데이터 작업 라벨 태그 - 난이도, 연기 */}
          {tag === "work" && (
            <>{item.difficulty && <LabelTag tags={[{ name: item.difficulty }]} />}</>
          )}
          {/* 수집환경 입력 */}
          {tag === "cond" && <DrawCondTag id={item.id} />}
          {tag === "val" && item.conds && <WeatherBadge conds={item.conds} />}
          {/* 이관된 데이터가 있을 경우 */}
          {item.transferred && <LabelTag tags={[{ name: "이관", active: true }]} />}
        </div>
        {previewQuery && (
          <GetPreview
            previewQuery={previewQuery}
            index={index + (currentPage - 1) * ROWS_PER_PAGE}
            view="thumb"
            tag={tag}
            currentImgType={currentImgType}
          >
            {item[currentImgType.thumb] ? (
              <img
                className={clsx(
                  "max-h-[398px] w-full object-contain",
                  previewQuery && "cursor-pointer"
                )}
                src={`${process.env.REACT_APP_SERVER_BASE_URL}/${item[currentImgType.thumb]}`}
                alt={item.filename}
                // 우클릭 방지
                onContextMenu={(e) => e.preventDefault()}
                onError={(e) => {
                  e.target.src = IC_empty;
                  e.target.className = "w-auto m-auto";
                }}
              />
            ) : (
              <EmptyImg />
            )}
          </GetPreview>
        )}
      </li>
    );
  }
);

/**
    @param {Object} props.conds 
  */
export const WeatherBadge = (props) => {
  const { conds } = props;

  return (
    <div className="flex gap-[16px]">
      {CONDITIONS.map((x, i) => {
        return (
          <div key={i} className={clsx("flex h-[16px] w-[16px] items-center")}>
            <img
              src={`images/icon_weather_${conds[x.value] ? x.value + "_on" : x.value}.png`}
              alt={x.ko}
            />
          </div>
        );
      })}
    </div>
  );
};

import React, { useRef, useEffect, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { ConfirmButton } from "../../components/organisms/actions";
import { addAllTag } from "../../components/organisms/StoreTag";
import { MessageModal } from "../../components/molecules/CommonModals";
import { useReload } from "../../lib/hooks";
import { currentMenuState } from "../../lib/stores";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { LRN_TAG_VALUE, VLD_AUTO_VALUE } from "../../lib/constants/constant";
import { vldFilter, vldAutoCheck, vldAllTagChange } from "./Vld.apis";
import {
  makeImgTypeList,
  VLD_DATA_COLS,
  VLD_RADSEG_TAB,
  VLD_CAMDET_TAB,
  VLD_CAMSEG_TAB,
  VLD_TYPE_CODE,
} from "./Vld.constants";
import {
  vldCntQuery,
  vldIdsQuery,
  vldListQuery,
  vldPreviewQuery,
  vldTagsState,
} from "./Vld.stores";
import {
  DownloadGps,
  VldMaskRgs,
  VldAutoCancel,
  VldListButton,
  VldSelectedListButton,
  VldSegAuto,
  VldDetAuto,
  VldInf,
  VldMapUpload,
  DownloadOrig,
  VldReset,
  SetVldTag,
  VldPropList,
  VldDelCheck,
  DownloadOrigAll,
} from "./VldActions";

const VldDataPage = () => {
  const currentMenu = useRecoilValue(currentMenuState);
  const { linkTo, urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, view, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    vldCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    vldIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  const setVldTags = useSetRecoilState(vldTagsState);
  const setAllAddTag = useSetRecoilState(addAllTag);

  const { listReload } = useReload();

  const loadingRef = useRef(null);

  const [autoCheck, setAutoCheck] = useState(1);
  const [isVldAutoInProgress, setIsVldAutoInProgress] = useState(false);

  const afterActionCallback = () => {
    linkTo({ pathname: currentMenu.menu.path, search: currentMenu.menu.query.clct });
  };

  const afterAutomationFinish = () => {
    listReload();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await vldAutoCheck({ ...urlParams });
        const { [gt_code === "seg" ? "seg_auto_status" : "det_auto_status"]: status } = data;
        setAutoCheck(status);
        if (status === VLD_AUTO_VALUE.AUTO) {
          setIsVldAutoInProgress(true);
        }
      } catch (e) {
        console.log(e.response?.data?.detail ?? "오류가 발생했습니다.");
      }
    };

    if (sensor_code === "cam") {
      fetchData();
    }
  }, []);

  const gtCodeLabel = gt_code === "seg" ? "선별" : "추론";

  return (
    <>
      <CommonTemplate
        listQuery={vldListQuery}
        state_cnt={state_cnt}
        contents_cnt={contents_cnt}
        contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
        filterApi={vldFilter}
        previewQuery={vldPreviewQuery}
        fullColumnData={VLD_DATA_COLS[`${sensor_code}`].tab1}
        columnData={VLD_DATA_COLS[`${sensor_code}`][`tab${tab}`] ?? []}
        hideColumn={VLD_DATA_COLS[`${sensor_code}`].hide.table[`tab${tab}`]}
        hideThumbColumn={VLD_DATA_COLS[`${sensor_code}`].hide.thumb[`tab${tab}`]}
        tabData={
          sensor_code === "rad"
            ? VLD_RADSEG_TAB
            : gt_code === "det"
              ? VLD_CAMDET_TAB
              : VLD_CAMSEG_TAB
        }
        groupColumnData={
          sensor_code === "rad"
            ? VLD_RADSEG_TAB
            : gt_code === "det"
              ? VLD_CAMDET_TAB
              : VLD_CAMSEG_TAB
        }
        viewData
        imgTypeData={makeImgTypeList(sensor_code)}
        statusCodeData={VLD_TYPE_CODE}
        setVldTags={setVldTags}
        tagType={
          tab === "2"
            ? "eval"
            : view === "thumb" && (tab === "1" || tab === "3" || tab === "4")
              ? "val"
              : false
        }
        actions={(data) =>
          renderActions({
            sensor_code,
            gt_code,
            tab,
            set_id,
            ...data,
            afterActionCallback,
            autoCheck,
            setAutoCheck,
            setAllAddTag,
          })
        }
        actionsNoPermit={(data) => renderActionsNoPermit({ sensor_code, gt_code, tab })}
      />
      {isVldAutoInProgress && (
        <MessageModal
          detail={`자동 ${gtCodeLabel} 진행 중인 데이터 셋은 진입이 불가합니다.`}
          closeModal={() => afterActionCallback()}
        />
      )}
    </>
  );
};

const renderActions = ({
  sensor_code,
  gt_code,
  tab,
  set_id,
  view,
  selectedItems,
  setSelectedItems,
  contents,
  afterActionCallback,
  autoCheck,
  setAutoCheck,
  setAllAddTag,
}) => {
  const updatedTagType = (tagType) => {
    selectedItems.forEach((selectedItemId) => {
      setAllAddTag({ id: selectedItemId, value: tagType });
    });
  };

  const TABS = {
    전체: 1,
    선별대기: 3,
    선별완료: 4,
  };

  const buttonsForAllContents = {
    cam: {
      seg: {
        [TABS.선별대기]: (
          <>
            <VldMaskRgs set_id={set_id} />
            <div className={autoCheck === VLD_AUTO_VALUE.NONE ? "block" : "hidden"}>
              <VldListButton title="자동 선별">
                <VldSegAuto
                  title={"즉시 선별"}
                  set_id={set_id}
                  action="auto"
                  type="list_button"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.AUTO)}
                />
                <VldSegAuto
                  title={"자동 선별 예약"}
                  subText={"예약 자동 선별 진행 시간: 매일 00시 ~ 06시"}
                  set_id={set_id}
                  action="book"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.BOOK)}
                  type="list_button"
                />
              </VldListButton>
            </div>
            <div className={autoCheck === VLD_AUTO_VALUE.BOOK ? "block" : "hidden"}>
              <VldListButton title="자동 선별">
                <VldSegAuto
                  title={"즉시 선별"}
                  set_id={set_id}
                  action="auto"
                  type="list_button"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.AUTO)}
                />
                <VldAutoCancel
                  title="자동 선별 예약 취소"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.NONE)}
                  type="list_button"
                />
              </VldListButton>
            </div>
            {autoCheck === VLD_AUTO_VALUE.AUTO && (
              <MessageModal
                title="자동 선별 시작"
                detail={`자동 선별을 시작합니다. 데이터셋 페이지에 접근할 수 없습니다.`}
                closeModal={() => afterActionCallback()}
              />
            )}
          </>
        ),
        [TABS.선별완료]: <></>,
      },
      det: {
        [TABS.선별대기]: (
          <>
            <div className={autoCheck === VLD_AUTO_VALUE.NONE ? "block" : "hidden"}>
              <VldListButton title="자동 추론">
                <VldDetAuto
                  title={"즉시 추론"}
                  set_id={set_id}
                  action="auto"
                  type="list_button"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.AUTO)}
                />
                <VldDetAuto
                  title={"자동 추론 예약"}
                  subText={"예약 자동 추론 진행 시간: 매일 00시 ~ 06시"}
                  set_id={set_id}
                  action="book"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.BOOK)}
                  type="list_button"
                />
              </VldListButton>
            </div>
            <div className={autoCheck === VLD_AUTO_VALUE.BOOK ? "block" : "hidden"}>
              <VldListButton title="자동 추론">
                <VldDetAuto
                  title={"즉시 추론"}
                  set_id={set_id}
                  action="auto"
                  type="list_button"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.AUTO)}
                />
                <VldAutoCancel
                  title="자동 추론 예약 취소"
                  afterActionCallback={() => setAutoCheck(VLD_AUTO_VALUE.NONE)}
                  type="list_button"
                />
              </VldListButton>
            </div>
            {autoCheck === VLD_AUTO_VALUE.AUTO && (
              <MessageModal
                detail={`자동 추론을 시작합니다. \n 데이터셋 페이지에 접근할 수 없습니다.`}
                closeModal={() => afterActionCallback()}
              />
            )}
          </>
        ),
      },
    },
    rad: {
      seg: {
        [TABS.전체]: (
          <>
            <VldPropList />
            <VldMapUpload />
            <DownloadGps count={contents.count} />
            <DownloadOrigAll count={contents.count} />
          </>
        ),
      },
    },
  };

  const buttonsForSelectedContents = {
    cam: {
      [TABS.선별대기]: {
        thumb: (
          <>
            <VldInf selectedItems={selectedItems} contents={contents.results} />
            <VldSelectedListButton title="선별 유형 선택" selectedItems={selectedItems}>
              <ConfirmButton
                count={selectedItems.length}
                api={vldAllTagChange}
                hasPath
                body={{ ids: selectedItems, tag_type: LRN_TAG_VALUE.WAIT }}
                actionName="대기"
                type="list_button"
                afterActionCallback={() => updatedTagType(LRN_TAG_VALUE.WAIT)}
              />
              <ConfirmButton
                count={selectedItems.length}
                api={vldAllTagChange}
                hasPath
                body={{ ids: selectedItems, tag_type: LRN_TAG_VALUE.EXCEPT }}
                actionName="제외"
                type="list_button"
                afterActionCallback={() => updatedTagType(LRN_TAG_VALUE.EXCEPT)}
              />
              <ConfirmButton
                count={selectedItems.length}
                api={vldAllTagChange}
                hasPath
                body={{ ids: selectedItems, tag_type: LRN_TAG_VALUE.ADD }}
                actionName="학습용"
                type="list_button"
                afterActionCallback={() => updatedTagType(LRN_TAG_VALUE.ADD)}
              />
            </VldSelectedListButton>
            <SetVldTag selectedItems={selectedItems} tag={"val"} />
          </>
        ),
        table: (
          <>
            <VldInf selectedItems={selectedItems} contents={contents.results} />
          </>
        ),
      },
      [TABS.선별완료]: {
        thumb: <VldReset selectedItems={selectedItems} />,
        table: <VldReset selectedItems={selectedItems} />,
      },
    },
    rad: {
      [TABS.전체]: {
        thumb: <DownloadOrig selectedItems={selectedItems} />,
        table: <DownloadOrig selectedItems={selectedItems} />,
      },
      [TABS.선별대기]: {
        thumb: (
          <>
            <VldSelectedListButton title="선별 유형 선택" selectedItems={selectedItems}>
              <ConfirmButton
                count={selectedItems.length}
                api={vldAllTagChange}
                hasPath
                body={{ ids: selectedItems, tag_type: LRN_TAG_VALUE.WAIT }}
                actionName="대기"
                type="list_button"
                afterActionCallback={() => updatedTagType(LRN_TAG_VALUE.WAIT)}
              />
              <ConfirmButton
                count={selectedItems.length}
                api={vldAllTagChange}
                hasPath
                body={{ ids: selectedItems, tag_type: LRN_TAG_VALUE.EXCEPT }}
                actionName="제외"
                type="list_button"
                afterActionCallback={() => updatedTagType(LRN_TAG_VALUE.EXCEPT)}
              />
              <ConfirmButton
                count={selectedItems.length}
                api={vldAllTagChange}
                hasPath
                body={{ ids: selectedItems, tag_type: LRN_TAG_VALUE.ADD }}
                actionName="학습용"
                type="list_button"
                afterActionCallback={() => updatedTagType(LRN_TAG_VALUE.ADD)}
              />
            </VldSelectedListButton>
            <SetVldTag selectedItems={selectedItems} tag={"val"} />
          </>
        ),
        table: <></>,
      },
      [TABS.선별완료]: {
        thumb: (
          <>
            <VldReset selectedItems={selectedItems} />
          </>
        ),
        table: (
          <>
            <VldReset selectedItems={selectedItems} />
          </>
        ),
      },
    },
  };
  return (
    <>
      {buttonsForAllContents[sensor_code]?.[gt_code]?.[tab]}
      <SelectCnt count={selectedItems.length} />
      {buttonsForSelectedContents[sensor_code]?.[tab]?.[view]}
      <VldDelCheck contents={contents.results} selectedItems={selectedItems} />
    </>
  );
};

const renderActionsNoPermit = ({ sensor_code, gt_code, tab }) => {
  const TABS = {
    전체: 1,
    선별대기: 3,
    선별완료: 4,
  };

  const buttons = {
    cam: {
      seg: {
        [TABS.선별완료]: <></>,
      },
    },
  };

  return buttons[sensor_code]?.[gt_code]?.[tab];
};

export default VldDataPage;

import React, { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { Beforeunload } from "react-beforeunload";
import clsx from "clsx";
import { NAVLUEContext } from "../../App";
import MENU_LIST from "../../lib/constants/menulist";
import { useReload } from "../../lib/hooks";
import {
  currentMenuState,
  currentPermissionState,
  currentUserState,
  isDwlState,
  previewState,
  userInfoQuery,
} from "../../lib/stores";
import { Loader, LoadFail } from "../atoms/Status";
import Floating from "../organisms/floating/Floating";
import ProfileModal from "../organisms/header/ProfileModal";
import PrivacyModal from "../organisms/header/PrivacyModal";
import DrawPreview from "../organisms/DrawPreview";
import CurrentPage from "../organisms/CurrentPage";
import Header from "../organisms/header/Header";
import { ForbiddenTemplate } from "./ForbiddenTemplate";

const Layout = (props) => {
  const { children } = props;
  const pathname = window.location.pathname;

  const { progressRows } = useContext(NAVLUEContext);

  const { state, contents } = useRecoilValueLoadable(userInfoQuery);
  const { infoReload } = useReload();

  const preview = useRecoilValue(previewState); // 미리보기 출력 여부
  const setCurrentPermission = useSetRecoilState(currentPermissionState);
  const [currentMenu, setCurrentMenu] = useRecoilState(currentMenuState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const { permissions, terms_agreed, password_invalid, internal } = currentUser;
  const isDwl = useRecoilValue(isDwlState);

  const [isLogout, setIsLogout] = useState(false);
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    // userInfo 정보 받아오기
    state === "hasValue" && setCurrentUser(contents);
    state === "hasError" && setStatus("fail");
  }, [state]);

  useEffect(() => {
    // currentUser 정보 또는 현재 메뉴가 변경되면 status 확인
    if (currentMenu?.menu?.permission) {
      if (currentMenu?.menu?.permission === "all") {
        setStatus("pass");
      } else if (internal === false) {
        if (!permissions.includes(currentMenu?.menu?.permission, 0)) {
          setStatus("forbidden");
        } else {
          setStatus("pass");
        }
      } else if (internal === true) {
        setStatus("pass");
      } else {
        setStatus("loading");
      }
    }
  }, [internal, permissions, currentMenu]);

  useEffect(() => {
    // pathname이 변경되면 currentMenu 변경
    MENU_LIST.map((i) =>
      i.menu_list.find(
        (j) =>
          pathname.includes(j.path) &&
          setCurrentMenu({
            category: i.category,
            menu: j,
          })
      )
    );
  }, [pathname]);

  useEffect(() => {
    // currentMenu 또는 permissions가 변경되면 현재 메뉴 권한 확인
    if (permissions) {
      setCurrentPermission(permissions.includes(currentMenu.menu.permission, 0));
    }
  }, [currentMenu, permissions]);

  return (
    <>
      {progressRows.length > 0 && !isLogout && !isDwl && (
        <Beforeunload
          onBeforeunload={(e) => {
            e.preventDefault();
          }}
        />
      )}
      <section className="flex h-screen flex-col items-center">
        {terms_agreed === false ? (
          <PrivacyModal main closeModal={infoReload} />
        ) : password_invalid === false ? (
          <ProfileModal main closeModal={infoReload} />
        ) : (
          <>
            <Header state={state} contents={contents} whenLogout={(data) => setIsLogout(data)} />
            {status === "loading" ? (
              <Loader />
            ) : status === "forbidden" ? (
              <ForbiddenTemplate />
            ) : status === "fail" ? (
              <LoadFail failType="데이터를 불러오는데 실패했습니다." />
            ) : (
              <>
                <div
                  className={clsx(
                    "flex h-[calc(100vh-40px)] w-full flex-col bg-gray100 pt-0 pr-[16px] pb-[16px] pl-[16px]",
                    (pathname === "/statistics" || pathname === "/main") && "overflow-y-scroll",
                    pathname === "/main" && "relative"
                  )}
                >
                  <CurrentPage />
                  <div
                    className={clsx(
                      "flex h-full w-full flex-col bg-white",
                      pathname === "/statistics" ? "overflow-unset" : "overflow-hidden"
                    )}
                  >
                    {children}
                  </div>
                </div>
                <Floating />
                {preview && <DrawPreview />}
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default React.memo(Layout);

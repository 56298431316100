import { useEffect, useState, useRef, useCallback } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useHotkeys } from "react-hotkeys-hook";
import clsx from "clsx";
import {
  previewCurrentState,
  previewInfoState,
  previewListState,
  imgTypeDataState,
  previewState,
} from "../../lib/stores";
import { AllLevelState, levelSelector, addLevel, resetLevels } from "./StoreLevel";
import { AllTagState, tagSelector, addTag, resetTags } from "./StoreTag";
import { CONDITIONS, LRN_TAG_VALUE } from "../../lib/constants/constant";
import { useModals, useReload } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { Loader, LoadFail } from "../atoms/Status";
import { EmptyModal } from "../molecules/CommonModals";
import { DrawCondTag } from "./CommonTags";
import { vldTagsState } from "../../pages/validation/Vld.stores";
import { SelectItem, SelectRadio } from "../atoms/SelectBox";
import { StepIndicator } from "../atoms/CommonAtoms";
import { vldTagChange } from "../../pages/validation/Vld.apis";
import { checkLevelChange } from "../../pages/check/Check.apis";

const DrawPreview = () => {
  const {
    history,
    urlParams,
    queryParams,
    queryParams: { tab },
  } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [vldTags, setVldTags] = useRecoilState(vldTagsState); // 선별 태그 정보

  const imgTypeData = useRecoilValue(imgTypeDataState); // 이미지 타입 옵션 리스트
  const previewList = useRecoilValue(previewListState); // 전체 리스트
  const setPreview = useSetRecoilState(previewState); // 미리보기 출력 여부
  const setResetLevels = useSetRecoilState(resetLevels);
  const setResetTags = useSetRecoilState(resetTags);
  const { listReload } = useReload();

  const [previewInfo, setPreviewInfo] = useRecoilState(previewInfoState); // 미리보기 고정정보
  const [previewCurrent, setPreviewCurrent] = useRecoilState(previewCurrentState); // 미리보기 현재정보

  const isDistWaitPage = previewInfo.tag === "dist" && tab === "2";

  const { state, contents } = useRecoilValueLoadable(
    previewInfo?.previewQuery({
      ...urlParams,
      tab: tab,
      id: previewList[previewCurrent.index],
      img_type: previewCurrent?.currentImgType?.pre,
    })
  );

  const previewInit = useRef(false); // 미리보기 창 생성 여부

  const [previewImg, setPreviewImg] = useState({});

  const imageDimensions = useRef({
    width: 0,
    height: 0,
  });
  const currentImageState = useRef({
    scale: 1,
    positionX: 0,
    positionY: 0,
  });
  const [currentImage, setCurrentImage] = useState({ src: "" });

  const [showControlPanel, setShowControlPanel] = useState(true);

  const imageWrapperRef = useRef(null);

  // 학습용 toggle용 함수
  const toggle = () => {
    const id = previewList[previewCurrent.index];

    if (vldTags.includes(id)) {
      setVldTags(vldTags.filter((x) => x !== id));
    } else {
      setVldTags([...vldTags, id]);
    }
  };

  const AllTag = useRecoilValue(AllTagState);
  const tags = useRecoilValue(tagSelector);
  const AllLevel = useRecoilValue(AllLevelState);
  const levels = useRecoilValue(levelSelector);

  const closePreview = () => {
    setPreview(false);
    setPreviewInfo(null);
    setPreviewImg({});

    if (Object.keys(levels).length) {
      submitData(checkLevelChange, setResetLevels, levels);
    }
    if (Object.keys(tags).length) {
      submitData(vldTagChange, setResetTags, tags);
    }
  };

  const submitData = async (checkFunction, resetFunction, data) => {
    try {
      await checkFunction(urlInfo, data);
      listReload();
      resetFunction();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";
      setModalStatus("hasError");
      console.log(err_detail);
      // setFailType(err_detail);
    }
  };

  const resetModal = () => {
    setModalStatus(null);
    closeModal();
  };

  const updateIdx = (index) => {
    setPreviewCurrent({
      ...previewCurrent,
      index,
    });
  };

  const handleArrClick = (arr) => {
    const currentIdx = Number(previewCurrent.index);
    const lastIdx = previewList.length - 1;

    switch (arr) {
      case "prev":
        if (currentIdx <= 0) {
          setModalStatus("first");
          openModal();
        } else {
          updateIdx(currentIdx - 1);
        }
        return;
      case "next":
        if (currentIdx >= lastIdx) {
          setModalStatus("last");
          openModal();
        } else {
          updateIdx(currentIdx + 1);
        }
        return;
      default:
        return;
    }
  };

  const handleFitToScreen = (useAnimation = true) => {
    if (
      imageWrapperRef.current &&
      imageDimensions.current.width > 0 &&
      imageDimensions.current.height > 0
    ) {
      const { width, height } = imageDimensions.current;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      const widthRatio = screenWidth / width;
      const heightRatio = screenHeight / height;

      const scale = Math.min(widthRatio, heightRatio);
      imageWrapperRef.current.setTransform(
        (screenWidth - width * scale) / 2,
        (screenHeight - height * scale) / 2,
        scale,
        useAnimation ? undefined : 0
      );
    }
  };

  const handleOriginalSize = () => {
    if (
      imageWrapperRef.current &&
      imageDimensions.current.width > 0 &&
      imageDimensions.current.height > 0
    ) {
      const { width, height } = imageDimensions.current;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      imageWrapperRef.current.setTransform(
        (screenWidth - width) / 2,
        (screenHeight - height) / 2,
        1
      );
    }
  };

  const setAddTag = useSetRecoilState(addTag);
  const setAddLevel = useSetRecoilState(addLevel);

  const changeDifficultyState = (direction = 1) => {
    if (!previewImg.id) return;

    const currentIndex = DIFFICULTY.indexOf(previewImg.difficulty);
    let nextIndex;

    if (currentIndex === 0) {
      // 현재 인덱스가 0이면 가운데부터 시작
      nextIndex = Math.floor(DIFFICULTY.length / 2);
    } else if (direction === 1) {
      // 증가: 마지막 인덱스까지만
      nextIndex = Math.min(currentIndex + 1, DIFFICULTY.length - 1);
    } else {
      // 감소: 1까지만 (0으로 가지 않음)
      nextIndex = Math.max(currentIndex - 1, 1);
    }

    setAddLevel({ id: previewImg.id, value: DIFFICULTY[nextIndex] });
    setToastMessage(DIFFICULTY[nextIndex]);
    setPreviewImg((prev) => ({
      ...prev,
      difficulty: DIFFICULTY[nextIndex],
    }));
  };

  const resetDifficultyState = () => {
    if (!previewImg.id) return;
    setAddLevel({ id: previewImg.id, value: DIFFICULTY[0] });
    setToastMessage(DIFFICULTY[0]);
    setPreviewImg((prev) => ({
      ...prev,
      difficulty: DIFFICULTY[0],
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      if (imageWrapperRef.current) {
        imageWrapperRef.current.centerView();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // next 이미지 응답을 기다리는 동안 prev 이미지를 출력하고
    // next 이미지 응답이 오면 그때 next 이미지로 state 변경
    if (state === "hasValue") {
      // 작업자 노출 케이스 정의
      let display_name;
      if (contents.worker === undefined) {
        display_name = null;
      } else if (contents.worker === null) {
        display_name = "-";
      } else {
        display_name = contents.worker.display_name;
      }
      setPreviewImg({
        index: previewCurrent.index + 1,
        id: contents.id,
        img: contents.img,
        filename: contents.filename,
        display_name,
        lon_lat: contents.lon_lat,
        rework_cnt: contents.rework_cnt,
        transferred: contents.transferred,
        conds: contents.conds,
        vld_type: AllTag[contents.id] ? AllTag[contents.id] : contents.vld_type,
        difficulty: levels[contents.id]
          ? levels[contents.id]
          : AllLevel[contents.id]
            ? AllLevel[contents.id]
            : contents.difficulty,
      });
    }
  }, [contents]);

  // if (Object.keys(tags).length) {
  //   console.log("tags");
  //   console.log(tags);
  // }
  // if (Object.keys(AllTag).length) {
  //   console.log("AllTag");
  //   console.log(AllTag);
  // }
  // if (Object.keys(levels).length) {
  //   console.log("levels");
  //   console.log(levels);
  // }
  // if (Object.keys(AllLevel).length) {
  //   console.log("AllLevel");
  //   console.log(AllLevel);
  // }

  // useEffect(() => {
  //   console.log("previewImg 현재 난이도");
  //   console.log(previewImg.difficulty);
  // }, [previewImg]);

  useEffect(() => {
    // 이미지 줌 스크롤
    if (document.getElementById("zoom_img")) {
      const imgElem = document.getElementById("zoom_img").parentNode.parentNode;
      imgElem.style.overflow = "scroll";
      imgElem.style.paddingTop = "2px";
    }
  }, [previewImg]);

  useEffect(() => {
    if (previewImg.img) {
      const img = new Image();
      img.src = `data:image/webp;base64,${previewImg.img}`;
      img.onload = () => {
        if (!previewInit.current) {
          imageDimensions.current = { width: img.width, height: img.height };
          handleFitToScreen(false); // 이미지 로드 완료 후 호출
          previewInit.current = true;
        } else {
          const scale =
            currentImageState.current.scale * (imageDimensions.current.width / img.width);
          imageWrapperRef.current.setTransform(
            currentImageState.current.positionX,
            currentImageState.current.positionY,
            scale,
            0
          );
          imageDimensions.current = { width: img.width, height: img.height };
        }
        setCurrentImage(img);
      };
    }
  }, [previewImg]);

  useEffect(() => {
    // 브라우저 뒤로가기를 감지하여 미리보기 끔
    let unlisten = history.listen((location) => {
      if (history.action === "POP") closePreview();
    });

    return () => unlisten();
  }, [history]);

  const lastKeyPressTime = useRef(0);

  const handleKeyPress = (e) => {
    const currentTime = new Date().getTime();
    if (currentTime - lastKeyPressTime.current < 50) {
      return; // 0.5초 이내의 키 입력은 무시
    }
    lastKeyPressTime.current = currentTime;

    switch (e.code) {
      case "Backquote":
        if (!imgTypeData[0]) return;
        setPreviewCurrent({
          ...previewCurrent,
          currentImgType: imgTypeData[0],
        });
        setToastMessage(imgTypeData[0].ko);
        return;
      case "Digit1":
        if (!imgTypeData[1]) return;
        setPreviewCurrent({
          ...previewCurrent,
          currentImgType: imgTypeData[1],
        });
        setToastMessage(imgTypeData[1].ko);
        return;
      case "Digit2":
        if (!imgTypeData[2]) return;
        setPreviewCurrent({
          ...previewCurrent,
          currentImgType: imgTypeData[2],
        });
        setToastMessage(imgTypeData[2].ko);
        return;
      case "Digit3":
        if (!imgTypeData[3]) return;
        setPreviewCurrent({
          ...previewCurrent,
          currentImgType: imgTypeData[3],
        });
        setToastMessage(imgTypeData[3].ko);
        return;
      case "Digit4":
        if (!imgTypeData[4]) return;
        setPreviewCurrent({
          ...previewCurrent,
          currentImgType: imgTypeData[4],
        });
        setToastMessage(imgTypeData[4].ko);
        return;
      case "Digit5":
        if (!imgTypeData[5]) return;
        setPreviewCurrent({
          ...previewCurrent,
          currentImgType: imgTypeData[5],
        });
        setToastMessage(imgTypeData[5].ko);
        return;
      case "ArrowLeft":
        handleArrClick("prev");
        return;
      case "ArrowRight":
        handleArrClick("next");
        return;
      case "Escape":
        closePreview();
        resetModal();
        return;
      case "KeyZ":
        if (previewInfo.tag === "val" && previewImg.id) {
          setAddTag({ id: previewImg.id, value: LRN_TAG_VALUE.WAIT });
          setToastMessage("대기");
        }
        return;
      case "KeyX":
        if (previewInfo.tag === "val" && previewImg.id) {
          setAddTag({ id: previewImg.id, value: LRN_TAG_VALUE.EXCEPT });
          setToastMessage("제외");
        }
        return;
      case "KeyC":
        if (previewInfo.tag === "val" && previewImg.id) {
          setAddTag({ id: previewImg.id, value: LRN_TAG_VALUE.ADD });
          setToastMessage("학습용");
        }
        return;
      case "KeyE":
        previewInfo.tag === "eval" && toggle();
        return;
      case "Equal":
      case "NumpadAdd":
        e.preventDefault();
        if (isDistWaitPage) {
          changeDifficultyState(1);
        }
        return;
      case "NumpadSubtract":
      case "Minus":
        e.preventDefault();
        if (isDistWaitPage) {
          changeDifficultyState(-1);
        }
        return;
      case "Digit0":
      case "Numpad0":
        if (isDistWaitPage) {
          resetDifficultyState();
        }
        return;
      default:
        console.log(e.code);
        return;
    }
  };

  useHotkeys(
    ["`", "0", "=", "+", "-", "1", "2", "3", "4", "5", "left", "right", "esc", "z", "x", "c", "e"],
    (e) => {
      handleKeyPress(e);
    },

    {
      combinationKey: ",",
    }
  );

  // Toast 메시지 기능
  const [toastMessage, setToastMessage] = useState();

  // 플로팅 버튼 영역 기능
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const timerRef = useRef(null);

  const handleMouseMove = () => {
    setIsDisplayed(true);
    resetTimer();
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (!isHovering) {
        setIsDisplayed(false);
      }
    }, 800);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
    setIsDisplayed(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    resetTimer();
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <>
      <ToastNotification value={toastMessage} />
      <div className="fixed inset-0 z-[80] bg-black/80" onMouseMove={handleMouseMove}>
        <div>
          {state === "loading" && <Loader styleType="preview" />}
          {state === "hasError" && (
            <div className="h-screen w-screen">
              <LoadFail failType="이미지를 불러올 수 없습니다." />
            </div>
          )}
          {(state === "hasValue" || state === "loading") && (
            <>
              <div className="relative">
                <div
                  // 우클릭 방지
                  onContextMenu={(e) => e.preventDefault()}
                >
                  {previewImg.transferred ? (
                    // 이관된 데이터 일 때
                    state !== "loading" && (
                      <div className="h-screen w-screen">
                        <LoadFail failType="이관된 데이터 입니다." />
                      </div>
                    )
                  ) : // 이관된 데이터가 아닐 때
                  previewImg.img ? (
                    // 서버에 이미지가 있을 때
                    <TransformWrapper
                      ref={imageWrapperRef}
                      minScale="0.2"
                      onTransformed={(ref) => {
                        currentImageState.current = {
                          ...currentImageState.current,
                          scale: ref.state.scale,
                          positionX: ref.state.positionX,
                          positionY: ref.state.positionY,
                        };
                      }}
                    >
                      <TransformComponent>
                        <img
                          id="zoom_img"
                          src={currentImage.src}
                          style={{
                            imageRendering: "pixelated",
                          }}
                          alt="preview"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  ) : (
                    // 서버에 이미지가 없을 때
                    state !== "loading" && (
                      <div className="h-screen w-screen">
                        <LoadFail failType="이미지가 없습니다." />
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* QNA 미리보기가 아닌 경우 */}
              {!previewInfo.qna && (
                <>
                  <div
                    className={`fixed bottom-[112px] left-[35px] z-10 ${
                      showControlPanel ? "translate-x-0" : "-translate-x-[calc(100%+35px)]"
                    } transition duration-300 ease-in-out`}
                  >
                    <div className="relative">
                      {/* 패널 화면 옆으로 숨기기 */}
                      <div className="flex min-w-[400px] flex-col gap-[16px] text-white">
                        <div
                          className={clsx(
                            "absolute right-0 z-20 flex w-[35px] cursor-pointer items-center hover:opacity-50",
                            {
                              "rotate-[0deg] transform": showControlPanel,
                              "rotate-[180deg] transform": !showControlPanel,
                              "right-[0px]": showControlPanel,
                              "right-[-59px]": !showControlPanel,
                            }
                          )}
                          onClick={() => {
                            setShowControlPanel((show) => !show);
                          }}
                        >
                          <div
                            className={clsx("relative left-[15px] inline-block h-[24px] w-[24px]")}
                            style={{ backgroundImage: "url('images/icon_preview_fold.png')" }}
                          />
                        </div>
                        <div>
                          <div className="flex items-center text-[18px] font-bold text-white">
                            {previewImg.rework_cnt > 0 && (
                              <StepIndicator
                                step={previewImg.rework_cnt}
                                cn={"mr-[10px] text-[14px] border border-solid border-white"}
                              />
                            )}
                            <span className="inline-block">
                              {previewImg.index}. {previewImg.filename}
                            </span>
                          </div>
                          {/* 학습 데이터 선별 - 선별 태그 */}
                          {(previewInfo.tag === "eval" || previewInfo.tag === "val") &&
                            tab === "3" && (
                              <div className="mt-[10px] flex flex-col gap-[4px]">
                                <DrawVldTagForPreview
                                  id={previewList[previewCurrent.index]}
                                  tag={previewInfo.tag}
                                  type={previewImg.vld_type}
                                />
                              </div>
                            )}
                        </div>
                        {/* 작업자, 수집환경, 난이도 중에 1개라도 없으면 해당 영역은 비활성화 - border 선 노출로 인한 예외처리 */}
                        {previewImg.display_name || previewImg.conds || previewImg.difficulty ? (
                          <div className="flex flex-col gap-[8px] border-t-[1px] border-b-[1px] border-[#e1e1e1] py-[16px]">
                            {/* 작업자 데이터 */}
                            {previewImg.display_name && (
                              <ul>
                                <li className="flex h-[30px] items-center gap-[8px]">
                                  <div className="mr-[16px] w-[56px]">작업자</div>
                                  {previewImg.display_name}
                                </li>
                              </ul>
                            )}

                            {/* 수집환경 데이터 */}
                            {previewImg.conds && (
                              <ul>
                                <li className="flex h-[30px] items-center gap-[8px]">
                                  <div className="mr-[16px] w-[56px]">수집환경</div>
                                  <CondTag conds={previewImg.conds} />
                                </li>
                              </ul>
                            )}

                            {/* 난이도 데이터 */}
                            {isDistWaitPage ? (
                              <ul>
                                <li className="flex h-[30px] items-center gap-[8px]">
                                  <div className="mr-[16px] w-[56px]">난이도</div>
                                  <RenderDifficulty
                                    item={previewImg}
                                    id={previewImg.id}
                                    value={previewImg.difficulty}
                                    onClick={setPreviewImg}
                                    onStateChange={setToastMessage}
                                  />
                                </li>
                              </ul>
                            ) : (
                              previewImg.difficulty !== undefined && (
                                <ul>
                                  <li className="flex h-[30px] items-center gap-[8px]">
                                    <div className="mr-[16px] w-[56px]">난이도</div>
                                    {previewImg.difficulty ? previewImg.difficulty : "-"}
                                  </li>
                                </ul>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* 이미지 타입 선택 */}
                        <div className="flex justify-between">
                          {imgTypeData?.map((x, i) => (
                            <SelectItem key={i}>
                              <SelectRadio
                                onChange={(e) => {
                                  setPreviewCurrent({
                                    ...previewCurrent,
                                    currentImgType: JSON.parse(e.target.value),
                                  });
                                  // console.log(e.target.value);
                                  setToastMessage(JSON.parse(e.target.value).ko);
                                }}
                                checked={previewCurrent.currentImgType.pre === x.pre}
                                value={JSON.stringify(x)}
                              />
                              <div className={clsx("text-white", "pl-[8px]", "whitespace-nowrap")}>
                                {x.ko}
                              </div>
                            </SelectItem>
                          ))}
                        </div>
                      </div>
                      {previewImg.lon_lat && previewImg.lon_lat.every((coord) => coord) && (
                        <div className="whtiespace-normal absolute top-[calc(100%+10px)] w-[330px] text-white">
                          <a
                            className="inline-block whitespace-nowrap text-[13px] hover:bg-gray-600"
                            target="_blank"
                            href={`https://earth.google.com/web/@${previewImg.lon_lat[1]},${previewImg.lon_lat[0]},-0.8a,3300d,35y,0h,0t,0r/data=OgMKATA`}
                            rel="noreferrer"
                          >
                            Pos : {previewImg.lon_lat[1]}, {previewImg.lon_lat[0]}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {/* 미리보기 플로팅 버튼 영역 */}
        {isDisplayed && (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {/* 공통 */}
            <button
              className={clsx(
                "fixed right-[32px] top-[32px] z-10 cursor-pointer rounded-full bg-[rgba(0,0,0,0.24)] p-[8px]"
              )}
              onClick={closePreview}
            >
              <div
                className="h-[24px] w-[24px]"
                style={{ backgroundImage: "url('images/icon_preview_close.png')" }}
              ></div>
              <span className="for-a11y">Close</span>
            </button>

            {/* QNA 미리보기가 아닌 경우 */}
            {!previewInfo.qna && (
              <>
                {/* 이미지 스케일 조절 / 맞춤, 100% */}
                <div className="fixed bottom-[32px] right-[32px] flex flex-col gap-[16px]">
                  <div className="flex items-center rounded-[56px] bg-[rgba(0,0,0,0.32)] p-[8px_16px]">
                    <button
                      className="h-[24px] w-[24px]"
                      style={{
                        backgroundImage: "url('images/icon_preview_scale_fit.png')",
                      }}
                      onClick={() => {
                        handleFitToScreen();
                        setToastMessage("맞춤");
                      }}
                    >
                      <span className="for-a11y">맞춤</span>
                    </button>
                    <div className="mx-[20px] h-[24px] w-[1px] bg-[#d0d0d0]"></div>
                    <button
                      className="h-[24px] w-[24px]"
                      style={{
                        backgroundImage: "url('images/icon_preview_scale_origin.png')",
                      }}
                      onClick={() => {
                        handleOriginalSize();
                        setToastMessage("100%");
                      }}
                    >
                      <span className="for-a11y">100%</span>
                    </button>
                  </div>
                  {/* 이전 / 다음 버튼 */}
                  <div className="flex items-center rounded-[40px] bg-[rgba(0,0,0,0.32)] p-[8px_16px]">
                    <button
                      className={clsx("inline-block h-[24px] w-[24px] cursor-pointer")}
                      style={{ backgroundImage: "url('images/icon_preview_prev.png')" }}
                      onClick={() => handleArrClick("prev")}
                    >
                      <span className="for-a11y">Prev</span>
                    </button>
                    <div className="mx-[20px] h-[24px] w-[1px] bg-[#d0d0d0]"></div>
                    <button
                      className={clsx("inline-block h-[24px] w-[24px] cursor-pointer")}
                      style={{ backgroundImage: "url('images/icon_preview_next.png')" }}
                      onClick={() => handleArrClick("next")}
                    >
                      <span className="for-a11y">Next</span>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {modal && (
        <EmptyModal
          closeModal={resetModal}
          detail={modalStatus === "first" ? "첫번째 이미지입니다." : "마지막 이미지입니다."}
        />
      )}
    </>
  );
};

const RenderDifficulty = ({ item, id, value, onClick, onStateChange }) => {
  const levels = useRecoilValue(levelSelector);
  const setAddLevel = useSetRecoilState(addLevel);

  const handleAddLevel = ({ id, value }) => {
    if (levels[id] === value) {
      setAddLevel({ id, value: DIFFICULTY[0] });
      onStateChange(DIFFICULTY[0]);
      onClick((prev) => ({
        ...prev,
        difficulty: DIFFICULTY[0],
      }));
    } else {
      setAddLevel({ id, value });
      onClick((prev) => ({
        ...prev,
        difficulty: value,
      }));
      onStateChange(value);
    }
  };

  return (
    <div className="flex gap-[15px]" tabIndex="-1">
      {DIFFICULTY.filter((level) => level !== "none").map((level) => (
        <button
          key={level}
          className={clsx(
            "flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-[8px] border text-center transition-colors focus:outline-none",
            level === (levels[id] ?? value)
              ? "border-[#fff] bg-[rgb(43,99,193)] text-white"
              : "border-[#d0d0d0] bg-[white] text-[#515151]"
          )}
          onClick={() => {
            handleAddLevel({ id, value: level });
          }}
        >
          {level}
        </button>
      ))}
    </div>
  );
};

const DrawVldTagForPreview = (props) => {
  const { tag, id, type } = props;
  const tags = useRecoilValue(tagSelector);
  const setAddTag = useSetRecoilState(addTag);

  const selectedStatus = (id, type) => {
    if (tags[id] === type) {
      setAddTag({ id, value: LRN_TAG_VALUE.WAIT });
    } else {
      setAddTag({ id, value: type });
    }
  };

  return (
    <>
      <div className="flex gap-[8px]">
        <div
          className={clsx(
            "flex h-[30px] w-[88px] cursor-pointer items-center justify-center rounded-[8px] border border-[#d0d0d0]",
            LRN_TAG_VALUE.EXCEPT === (tags[id] ? tags[id] : type)
              ? "!border-[#E20000] bg-[#E20000] text-white"
              : "bg-white text-[#333]"
          )}
          onClick={() => selectedStatus(id, LRN_TAG_VALUE.EXCEPT)}
        >
          <div>제외</div>
        </div>
        <div
          className={clsx(
            "flex h-[30px] w-[88px] cursor-pointer items-center justify-center rounded-[8px] border border-[#d0d0d0]",
            LRN_TAG_VALUE.ADD === (tags[id] ? tags[id] : type)
              ? "!border-primary bg-primary text-white"
              : "bg-white text-[#333]"
          )}
          onClick={() => selectedStatus(id, LRN_TAG_VALUE.ADD)}
        >
          <div>{tag === "val" ? "학습용" : tag === "eval" ? "평가용" : tag}</div>
        </div>
      </div>
    </>
  );
};

export const CondTag = (props) => {
  const { conds } = props;

  if (checkAllFalsy(conds)) return "-";

  return (
    <div className="flex">
      {CONDITIONS.filter((x) => conds[x.value]).map((x, i) => (
        <div key={i}>
          {i !== 0 && ","} {x.ko}
        </div>
      ))}
    </div>
  );
};

const checkAllFalsy = (obj) => !Object.values(obj).some((value) => value);

const ToastNotification = ({ value }) => {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setMessage(`${value}`);
      setShowToast(true);

      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [value]);

  return (
    <div
      className={`fixed top-4 left-1/2 z-[100] -translate-x-1/2 transform transition-opacity duration-300 ease-in-out ${showToast ? "opacity-100" : "pointer-events-none opacity-0"} `}
    >
      <div className="flex h-[40px] items-center rounded-[24px] bg-black bg-opacity-75 px-[48px] text-white shadow-lg">
        {message}
      </div>
    </div>
  );
};

const DIFFICULTY = ["none", "L-1", "L0", "L1", "L2", "L3", "L4", "L5"];

export default DrawPreview;
